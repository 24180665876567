import React from "react";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { reducer } from 'redux-server-state'
import thunk from "redux-thunk";
import { serverState } from './initState'

const rootReducer = combineReducers({serverState: reducer})

let store = createStore(rootReducer,serverState, applyMiddleware(thunk))

const windowGlobal = typeof window !== "undefined" && window;

if (windowGlobal) {
  // only on the client
  const composeEnhancer =
    windowGlobal.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // use applyMiddleware to add the thunk middleware to the store
  store = createStore(rootReducer, serverState, composeEnhancer(applyMiddleware(thunk)));
}



const RWrapper = ({ element }) => (
  <Provider store={store}>
      {element}
  </Provider>
);

export default RWrapper